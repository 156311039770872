export const MESSAGES = {
  ERRORS: {
    EMAIL_CHANGE:
      "メールアドレスの変更に失敗しました。\nしばらく経ってからもう一度お試しください。",
    PASSWORD_WRONG:
      "入力されたパスワードが違います。\n正しいパスワードを入力しもう一度お試しください。",
    PASSWORD_CHANGE: "パスワードの変更に失敗しました。\nしばらく経ってからもう一度お試しください。",
    UNEXPECTED: "予期せぬ問題が発生しました。\nしばらく経ってからもう一度お試しください。",
    CODE_EXPIRED:
      "コードの有効期限切れ\nまたは失敗回数が5回となりました。\n前画面より再度メールを送付してください。",
    CODE_FAILED: "入力されたコードが違います。\n再入力し、もう一度お試しください。",
    EMAIL_USED: "入力されたメールアドレスは使用済みです。",
  },
};
