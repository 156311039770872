<template>
  <v-container class="px-0">
    <div class="my-2"></div>

    <!-- 各種設定 -->
    <div v-for="(menu, index) in menus" :key="index" class="cursor-pointer" @click="action(index)">
      <div class="flex px-4">
        <div class="flex justify-between cursor-pointer hover:opacity-85">
          <div>{{ menu }}</div>
          <div>
            <v-icon> mdi-chevron-right </v-icon>
          </div>
        </div>
      </div>

      <v-divider class="my-4 px-0"></v-divider>
    </div>

    <!-- 利用規約ダイアログ -->
    <v-dialog v-model="termsDialog.show" max-width="480">
      <terms-dialog v-if="termsDialog.show" @close="termsDialog.show = false"></terms-dialog>
    </v-dialog>

    <!-- プライバシーポリシーダイアログ -->
    <v-dialog v-model="privacyDialog.show" max-width="480">
      <privacy-dialog
        v-if="privacyDialog.show"
        @close="privacyDialog.show = false"
      ></privacy-dialog>
    </v-dialog>

    <!-- 外部サイト移動確認ダイアログ -->
    <v-dialog v-model="externalSiteConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="externalSiteConfirmDialog.show"
        title="外部サイトへ移動します。よろしいですか？"
        @ok="toExternalSite"
        @cancel="externalSiteConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>

    <!-- ログアウト確認ダイアログ -->
    <v-dialog v-model="logoutConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="logoutConfirmDialog.show"
        title="ログアウトしますか？"
        :message="`サービスをご利用になるには\n再度ログインしていただく必要があります`"
        @ok="logout"
        @cancel="logoutConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import Confirm from "@/components/dialogs/Confirm";
import Privacy from "@/components/dialogs/Privacy";
import Terms from "@/components/dialogs/Terms";
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import fcmTokenMixin from "@/mixins/fcmTokenMixin";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "Setting",
  mixins: [errorHandlerMixin(), fcmTokenMixin()],
  components: { ConfirmDialog: Confirm, TermsDialog: Terms, PrivacyDialog: Privacy },
  data: () => ({
    version: require("../../../package.json").version,

    menus: [
      // "文字サイズ変更  ",
      "メールアドレス変更",
      "パスワード変更",
      "利用規約",
      "プライバシーポリシー",
      "お問い合わせ",
      "ログアウト",
    ],

    // 利用規約ダイアログ
    termsDialog: {
      show: false,
    },

    // プライバシーポリシーダイアログ
    privacyDialog: {
      show: false,
    },

    // 外部サイト移動確認ダイアログ
    externalSiteConfirmDialog: {
      targetUrl: "",
      show: false,
    },

    // ログアウト確認ダイアログ
    logoutConfirmDialog: {
      show: false,
    },
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    action(index) {
      switch (this.menus[index]) {
        case "メールアドレス変更":
          this.$router.push("/email-change").catch(() => {});
          break;

        case "パスワード変更":
          this.$router.push("/password-change").catch(() => {});
          break;

        case "利用規約":
          this.termsDialog.show = true;
          break;

        case "プライバシーポリシー":
          this.privacyDialog.show = true;
          break;

        case "お問い合わせ":
          var { lastName, firstName, lastNameKana, firstNameKana, email } =
            this.$store.state.user.selfUser;

          // 氏名
          var queryString = `${
            process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_NAME
          }=${encodeURIComponent(`${lastName} ${firstName}`)}`;
          // 氏名かな
          queryString += `&${
            process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_NAME_KANA
          }=${encodeURIComponent(`${lastNameKana} ${firstNameKana}`)}`;
          // メールアドレス
          queryString += `&${
            process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_EMAIL
          }=${encodeURIComponent(email)}`;
          // バージョン
          queryString += `&${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_VERSION}=${this.version}`;

          this.confirmExternalSite(
            `${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_URL}?${queryString}`
          );
          break;

        case "ログアウト":
          this.logoutConfirmDialog.show = true;
          break;

        default:
          alert("TODO");
      }
    },
    // 外部サイト移動の確認ダイアログを表示
    confirmExternalSite(targetUrl) {
      this.externalSiteConfirmDialog.targetUrl = targetUrl;
      this.externalSiteConfirmDialog.show = true;
    },
    // 外部サイトへ移動
    toExternalSite() {
      const targetUrl = this.externalSiteConfirmDialog.targetUrl;
      this.externalSiteConfirmDialog.show = false;
      if (targetUrl) {
        window.open(targetUrl, "_blank");
      }
    },
    async logout() {
      try {
        const auth = getAuth();
        const uid = auth.currentUser?.uid;
        if (uid) {
          // FCMトークンの削除処理
          await this.deleteFcmToken(uid);

          // ログアウト処理
          await signOut(auth);

          // ネイティブアプリの場合、通知とバッジ(iOSのみ)を削除する
          if (window.navigator.userAgent.indexOf("WV_app") != -1) {
            await window.flutter_inappwebview.callHandler("resetNotificationAndBadge");
          }
        }
        this.$router.push("/").catch(() => {});
      } catch (e) {
        // TODO: エラーハンドリング
        this.showError(MESSAGES.ERRORS.UNEXPECTED);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
