<template>
  <v-container class="h-full flex justify-center items-center">
    <div>
      <!-- メッセージ -->
      <div>メールアドレスが変更されました。</div>

      <!-- 戻るボタン -->
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="0"
          block
          @click="$router.push('/setting').catch(() => {})"
        >
          戻る
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "EmailChangeComplete",
};
</script>
