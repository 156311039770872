<template>
  <v-container class="px-2 h-full flex flex-col">
    <!-- 検索 -->
    <div>
      <v-text-field
        v-model="filterKeyword"
        placeholder="検索"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
      ></v-text-field>
    </div>

    <div
      v-for="(filterdGroup, index) in filterdGroups"
      :key="index"
      class="cursor-pointer mb-4"
      @click="redirectToChat(filterdGroup.id)"
    >
      <div class="flex gap-4 items-center cursor-pointer hover:opacity-85">
        <!-- アイコン -->
        <v-avatar size="70">
          <v-img :src="require(`../../assets/logo.svg`)"> </v-img>
        </v-avatar>

        <div class="flex flex-col">
          <div class="flex justify-between">
            <!-- グループ名 -->
            <!-- TODO: chatGroupsのnameを削除し、patientUidから参照する-->
            <div>{{ filterdGroup.name }}（{{ filterdGroup.uids.length }}）</div>

            <!-- 最新メッセージ日付 -->
            <div class="text-gray-400">
              {{ filterdGroup.latestMessage?.datetime }}
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <!-- 最新メッセージ -->
            <div class="line-clamp-1 text-gray-400">
              {{ filterdGroup.latestMessage?.message }}
            </div>

            <!-- 未読メッセージ数 -->
            <div v-if="getUnreadMessageCount(filterdGroup.id)">
              <v-chip color="red" dark small>
                {{ getUnreadMessageCount(filterdGroup.id) }}
              </v-chip>
            </div>
          </div>
        </div>

        <!-- 矢印アイコン -->
        <v-icon> mdi-chevron-right </v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ChatGroupList",
  data: () => ({
    // フィルターキーワード
    filterKeyword: "",
  }),
  computed: {
    // フィルター済みチャットグループ
    filterdGroups() {
      const { chatGroups } = this.$store.state.chat;

      return this.filterKeyword.length > 0
        ? chatGroups.filter((grpup) => {
            return grpup.name.indexOf(this.filterKeyword) != -1;
          })
        : chatGroups;
    },
  },
  created() {},
  mounted() {
    // チャットグループが1つしかない場合はチャット画面に遷移
    const { chatGroups } = this.$store.state.chat;
    if (chatGroups.length === 1) {
      this.redirectToChat(chatGroups[0].id);
    }
  },
  methods: {
    // 未読メッセージ数を取得
    getUnreadMessageCount(groupId) {
      const chatGroup = this.$store.state.chat.chatGroups.find(
        (chatGroup) => chatGroup.id == groupId
      );
      return chatGroup.unreadMessageCount;
    },

    // チャット画面に遷移
    redirectToChat(groupId) {
      this.$router.push(`/chat/${groupId}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
