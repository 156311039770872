<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="h-full flex justify-center">
      <div class="flex flex-col">
        <!-- メッセージ -->
        <div class="mb-4">
          <div>メールアドレス変更コードをメールでお送りしました。</div>
          <div>30分以内にメールアドレス変更コードを入力し、メールアドレスを変更してください。</div>
          <!-- 注釈 -->
          <div class="mt-4 mb-1">【メールが届かない場合】</div>

          <ul class="list-disc">
            <li>入力いただいたメールアドレスに間違いがないかご確認ください</li>
            <li>
              迷惑メール設定をされている場合、<span class="text-rose-500 font-bold"
                >@heart-diary.com</span
              >
              の受信許可設定をお願いします。
            </li>
            <li>
              上記ご対応をいただいてもメールが届かない場合には画面下部よりお問い合せください。
            </li>
          </ul>
        </div>

        <div>
          <validation-provider v-slot="{ errors }" name="コード" rules="required">
            <v-text-field
              v-model="code"
              label="コード"
              type="text"
              outlined
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </div>

        <div class="space-y-4">
          <v-btn
            color="primary"
            elevation="0"
            block
            :disabled="invalid"
            @click="sendEmailChangeMail"
          >
            変更
          </v-btn>
        </div>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";

export default {
  name: "EmailChangeConfirm",
  mixins: [errorHandlerMixin()],
  props: {
    codeId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    // コード
    code: "",
  }),
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // メールアドレス変更処理実施
    async sendEmailChangeMail() {
      try {
        this.incrementRunningApiCount();

        // コードを送付して、メールアドレス変更処理を実施
        const func = this.$httpsCallable(this.$functions, "emailchangeconfirm");
        await func({
          codeId: this.codeId,
          code: this.code,
        });

        this.decrementRunningApiCount();

        this.$router.push("/email-change-complete").catch(() => {});
      } catch (error) {
        let message = "";
        switch (error.message) {
          case "mail-change/code-expired":
            message = MESSAGES.ERRORS.CODE_EXPIRED;
            break;
          case "mail-change/code-failed":
            message = MESSAGES.ERRORS.CODE_FAILED;
            break;
          default:
            message = MESSAGES.ERRORS.UNEXPECTED;
        }
        this.showError(message);
        this.decrementRunningApiCount();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
