import { PASSWORD_MIN_LENGTH } from "@/const/const";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import Vue from "vue";
import VueAxios from "vue-axios";
import App from "./App.vue";
import "./assets/main.scss";
import "./assets/tailwind.css";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/index";

import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
// See. https://vee-validate.logaretm.com/v3/guide/rules.html#rules
import ja from "vee-validate/dist/locale/ja.json";
import { email, max, required } from "vee-validate/dist/rules";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

extend("required", required);
extend("email", email);

extend("email_confirm", {
  params: ["email"],
  validate: (value, { email }) => {
    if (email != value) {
      return "メールアドレスが一致しません";
    }
    return true;
  },
});

extend("max", max);
extend("password", {
  validate: (value) => {
    // 文字数チェック
    if (value.length < PASSWORD_MIN_LENGTH) {
      return `パスワードは${PASSWORD_MIN_LENGTH}文字以上で入力してください`;
    }

    // 英字・数字・半角記号の有無チェック
    if (!/[A-Za-z]/.test(value) || !/\d/.test(value) || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return "パスワードには英字・数字・記号を含めてください";
    }

    return true;
  },
});

extend("password_confirm", {
  params: ["password"],
  validate: (value, { password }) => {
    if (password != value) {
      return "パスワードが一致しません";
    }
    return true;
  },
});

localize("ja", ja);
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

moment.locale("ja", {
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});
Vue.prototype.moment = moment;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
if (process.env.VUE_APP_ENV === "development") {
  // Emulator に接続
  // Auth
  connectAuthEmulator(
    getAuth(),
    `http://${process.env.VUE_APP_EMULATOR_HOST}:${process.env.VUE_APP_EMULATOR_AUTH_PORT}`
  );
  // Firestore
  connectFirestoreEmulator(
    getFirestore(),
    process.env.VUE_APP_EMULATOR_HOST,
    process.env.VUE_APP_EMULATOR_FIRESTORE_PORT
  );
  connectFunctionsEmulator(
    getFunctions(firebaseApp, "asia-northeast1"),
    process.env.VUE_APP_EMULATOR_HOST,
    process.env.VUE_APP_EMULATOR_FUNCTIONS_PORT
  );
}

// AppCheckデバッグモードの有効化(yarn起動コマンドにて指定)
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_CHECK_DEBUG === "true";

// App Checkの初期化
if (process.env.VUE_APP_APP_CHECK_KEY) {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_APP_CHECK_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

Vue.prototype.$firestore = getFirestore();
Vue.prototype.$functions = getFunctions(firebaseApp, "asia-northeast1");
Vue.prototype.$httpsCallable = httpsCallable;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// Flutter側からrouterを操作するためにwindowへ設定
window.router = router;
