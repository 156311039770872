<template>
  <Dialog @close="$emit('close')">
    <!-- タイトル -->
    <template #[`title`]>
      <span class="text-base">利用規約</span>
    </template>

    <!-- コンテンツ -->
    <template #[`contents`]>
      <div class="border-2 p-2">
        <p class="mb-4">
          この利用規約（以下、「本規約」といいます。）は、鹿児島大学（以下、「弊学」といいます。）がこのアプリ上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
        </p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第1条（適用）</h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            本規約は、ユーザーと弊学との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </li>
          <li>
            弊学は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第2条（利用登録）</h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            本サービスにおいては、登録希望者が本規約に同意の上、弊学の定める方法によって利用登録を申請し、弊学がこれを承認することによって、利用登録が完了するものとします。
          </li>
          <li>
            弊学は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
            <ol
              class="pl-6 mt-2 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
            >
              <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
              <li>本規約に違反したことがある者からの申請である場合</li>
              <li>その他、弊学が利用登録を相当でないと判断した場合</li>
            </ol>
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第3条（ユーザーIDおよびパスワードの管理）
        </h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
          </li>
          <li>
            ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。弊学は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
          </li>
          <li>
            ユーザーIDおよびパスワードが第三者によって使用されたことによって生じた損害は、弊学に故意又は重大な過失がある場合を除き、弊学は一切の責任を負わないものとします。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第4条（禁止事項）</h2>
        <p>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>本サービスのサーバーまたはネットワークの機能を破壊し、妨害する行為</li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正アクセスをし、またはこれを試みる行為</li>
          <li>他のユーザーに成りすます行為</li>
          <li>本サービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
          <li>その他、弊学が不適切と判断する行為</li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第5条（本サービスの提供の停止等）
        </h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            弊学は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          </li>
          <ol
            class="pl-6 mt-2 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
          >
            <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
            <li>
              地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
            </li>
            <li>コンピュータまたは通信回線等が事故により停止した場合</li>
            <li>その他、弊学が本サービスの提供が困難と判断した場合</li>
          </ol>
          <li>
            弊学は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第6条（利用制限および登録抹消）
        </h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            弊学は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
          </li>

          <ol
            class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
          >
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>登録事項に虚偽の事実があることが判明した場合</li>
            <li>料金等の支払債務の不履行があった場合</li>
            <li>弊学からの連絡に対し、一定期間返答がない場合</li>
            <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
            <li>その他、弊学が本サービスの利用を適当でないと判断した場合</li>
          </ol>
          <li>
            弊学は、本条に基づき弊学が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第7条（退会）</h2>

        <p>ユーザーは、弊学の定める退会手続により、本サービスから退会できるものとします。</p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第8条（保証の否認および免責事項）
        </h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            弊学は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          </li>
          <li>
            弊学は、本サービスに起因してユーザーに生じたあらゆる損害について、弊学の故意又は重過失による場合を除き、一切の責任を負いません。ただし、本サービスに関する弊学とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
          </li>
          <li>
            前項ただし書に定める場合であっても、弊学は、弊学の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（弊学またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、弊学の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          </li>
          <li>
            弊学は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第9条（サービス内容の変更等）
        </h2>
        <p>
          弊学は、ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
        </p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第10条（利用規約の変更）</h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>
            弊学は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
          </li>
          <ol
            class="pl-6 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
          >
            <li>本規約の変更がユーザーの一般の利益に適合するとき。</li>
            <li>
              本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
            </li>
          </ol>
          <li>
            弊学はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
          </li>
        </ol>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第11条（個人情報の取扱い）</h2>
        <p>
          弊学は、本サービスの利用によって取得する個人情報については、弊学「プライバシーポリシー」に従い適切に取り扱うものとします。
        </p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第12条（通知または連絡）</h2>
        <p>
          ユーザーと弊学との間の通知または連絡は、弊学の定める方法によって行うものとします。弊学は、ユーザーから、弊学が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
        </p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">
          第13条（権利義務の譲渡の禁止）
        </h2>
        <p>
          ユーザーは、弊学の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </p>

        <h2 class="font-bold border-l-4 border-[#39618c] pl-2 mb-4">第14条（準拠法・裁判管轄）</h2>
        <ol
          class="pl-6 mb-4 list-decimal list-outside marker:text-[#39618c] marker:font-bold space-y-2"
        >
          <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
          <li>
            本サービスに関して紛争が生じた場合には、鹿児島地方裁判所を専属的合意管轄とします。
          </li>
        </ol>

        <p>以上</p>
      </div>
    </template>
    <!-- 閉じるボタン -->
    <template #[`footer-buttons`]>
      <v-btn @click="$emit('close')"><div>閉じる</div> </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/templates/Dialog";

export default {
  name: "Terms",
  components: {
    Dialog,
  },
};
</script>
