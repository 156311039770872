import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { mapActions } from "vuex";

export default function () {
  return {
    methods: {
      ...mapActions("user", ["setSelfUser"]),

      // 自身のユーザー情報を取得
      async getSelfUser(uid) {
        const db = this.$firestore;
        const docSnap = await getDoc(doc(db, "users", uid));

        if (docSnap.exists()) {
          // Store に設定
          this.setSelfUser({ uid, selfUser: docSnap.data() });
        } else {
          // TODO: エラーハンドリング
          alert("ユーザー情報が存在しません");
        }
      },

      // 自身のユーザー情報を購読
      subscribeSelfUser(uid) {
        const db = this.$firestore;

        return onSnapshot(
          doc(db, "users", uid),
          (snapshot) => {
            if (!snapshot.empty) {
              this.setSelfUser({ uid, selfUser: snapshot.data() });
            } else {
              // TODO: エラーハンドリング
              alert("ユーザー情報が存在しません");
            }
          },
          () => {
            location.reload();
          }
        );
      },
    },
  };
}
