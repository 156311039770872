<template>
  <v-card class="text-center">
    <v-card tile flat>
      <!-- タイトル -->
      <v-card-text class="flex items-center justify-center teal">
        <v-icon color="white" large left> mdi-help-circle-outline </v-icon>
        <span class="text-h6 font-light text-white ml-2"> {{ title }} </span>
      </v-card-text>
    </v-card>

    <div class="p-4">
      <!-- 説明文 -->
      <v-card-text v-if="description" class="px-0 text-left whitespace-pre-line">
        {{ description }}
      </v-card-text>

      <v-card-actions class="flex justify-center">
        <!-- 閉じるボタン -->
        <v-btn class="flex-1" color="teal" outlined @click="$emit('close')" aria-label="閉じる">
          閉じる
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  // ヘルプダイアログ
  name: "HelpDialog",
  props: {
    // タイトル
    title: {
      type: String,
      require: true,
    },
    // 説明文
    description: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped>
.v-card {
  border-width: inherit !important;
}
</style>
