<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="h-full flex justify-center">
      <div class="flex flex-col">
        <!-- メッセージ -->
        <div class="mb-4">
          <div>登録したメールアドレスを入力してください。</div>
          <div>パスワード再設定コードをメールでお送りします。</div>
          <div>コードの有効期限は30分間となっておりますので、速やかにご入力をお願いします。</div>
        </div>

        <!-- メールアドレス -->
        <div>
          <validation-provider v-slot="{ errors }" name="メールアドレス" rules="required|email">
            <v-text-field
              v-model="email"
              label="メールアドレス"
              type="email"
              prepend-inner-icon="mdi-email-outline"
              outlined
              dense
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </div>

        <div class="space-y-4">
          <!-- 送信ボタン -->
          <v-btn
            color="primary"
            elevation="0"
            block
            :disabled="invalid"
            @click="sendPasswordResettingMail"
          >
            送信
          </v-btn>

          <!-- 戻るボタン -->
          <v-btn color="grey" outlined block @click="$router.push('/').catch(() => {})">
            ログイン画面に戻る
          </v-btn>
        </div>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";

export default {
  name: "PasswordResetting",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // メールアドレス
    email: "",
  }),
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // パスワード再設定メール送信
    async sendPasswordResettingMail() {
      try {
        this.incrementRunningApiCount();

        // コードを生成し、ドキュメントのUUID取得
        const func = this.$httpsCallable(this.$functions, "passwordreset");
        const res = await func({ email: this.email, types: ["patient", "member"] });

        this.decrementRunningApiCount();

        this.$router.push(`/password-resetting-confirm/${res.data.codeId}`).catch(() => {});
      } catch {
        this.showError(MESSAGES.ERRORS.UNEXPECTED);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
