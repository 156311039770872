<template>
  <v-container class="px-2 h-full flex-col">
    <!-- 患者 -->
    <div>{{ patientCategoryName }}</div>
    <div class="px-10 mb-4">
      <div class="flex gap-4 items-center">
        <!-- アイコン -->
        <v-avatar size="70">
          <v-img :src="require(`../../assets/logo.svg`)"> </v-img>
        </v-avatar>
        <div>{{ patient.lastName }} {{ patient.firstName }}</div>
      </div>
    </div>

    <!-- メンバー一覧 -->
    <div>メンバー</div>
    <div v-for="(member, index) in members" :key="`member-${index}`" class="px-10 mb-4">
      <div class="flex gap-4 items-center">
        <!-- アイコン -->
        <v-avatar size="70">
          <v-img :src="require(`../../assets/logo.svg`)"> </v-img>
        </v-avatar>
        <div>{{ member.lastName }} {{ member.firstName }}（{{ member.type }}）</div>
      </div>
    </div>

    <!-- 医師一覧 -->
    <div>XXX病院</div>
    <div v-for="(doctor, index) in doctors" :key="`doctor-${index}`" class="px-10 mb-4">
      <div class="flex gap-4 items-center">
        <!-- アイコン -->
        <v-avatar size="70">
          <v-img :src="require(`../../assets/logo.svg`)"> </v-img>
        </v-avatar>
        <div>{{ doctor.lastName }} {{ doctor.firstName }}</div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ChatGroupMembers",
  data: () => ({
    // TODO: DB から取得する
    // 患者配列
    patient: { firstName: "太郎", lastName: "山田" },
    // 患者のメンバー
    members: [
      { firstName: "太郎", lastName: "山田", type: "息子" },
      { firstName: "花子", lastName: "山田", type: "娘" },
      { firstName: "花子", lastName: "山田", type: "娘" },
    ],
    // 医療関係者
    doctors: [
      { firstName: "医師", lastName: "佐藤" },
      { firstName: "看護師", lastName: "佐藤" },
      { firstName: "看護師", lastName: "佐藤" },
      { firstName: "医師", lastName: "佐藤" },
      { firstName: "看護師", lastName: "佐藤" },
      { firstName: "看護師", lastName: "佐藤" },
    ],
  }),
  computed: {
    patientCategoryName() {
      // TODO: ログインユーザーが患者自身か判定
      const isPatientSelf = false;
      return isPatientSelf ? "自分" : "患者";
    },
  },

  methods: {
    // TODO: FirestoreDatabaseからchatGroups以外のデータも取得できるようにし、表示させる
  },
};
</script>

<style lang="scss" scoped></style>
