<template>
  <v-container class="h-full flex justify-center items-center">
    <div>
      <!-- メッセージ -->
      <div>パスワードが再設定されました。</div>
      <div>ログイン画面よりログインしてください。</div>

      <!-- 戻るボタン -->
      <div class="mt-4">
        <v-btn color="primary" elevation="0" block @click="$router.push('/').catch(() => {})">
          ログイン画面に戻る
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PasswordResettingComplete",
};
</script>
