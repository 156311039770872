import { render, staticRenderFns } from "./NewPasswordSetting.vue?vue&type=template&id=c97d6486&scoped=true"
import script from "./NewPasswordSetting.vue?vue&type=script&lang=js"
export * from "./NewPasswordSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c97d6486",
  null
  
)

export default component.exports