const getters = {};

const getDefaultState = () => ({
  // 自身のユーザー情報
  selfUser: null,

  // メンバー情報
  members: [],

  // 医師情報
  doctors: [],
});

const state = getDefaultState();

const mutations = {
  // 自身のユーザー情報を設定
  setSelfUser: (state, { uid, selfUser }) => {
    state.selfUser = { uid, ...selfUser };
  },

  // 自身のメールアドレスを設定
  setSelfUserEmail: (state, { email }) => {
    state.selfUser.email = email;
  },

  // メンバー情報を追加 or 更新
  setMember: (state, { uid, member }) => {
    const index = state.members.findIndex((f) => f.uid === uid);

    if (index !== -1) {
      state.members.splice(index, 1, { uid, ...state.members[index], ...member });
    } else {
      state.members.push({ uid, ...member });
    }
  },

  // 医師情報を追加 or 更新
  setDoctor: (state, { uid, doctor }) => {
    const index = state.doctors.findIndex((f) => f.uid === uid);

    if (index !== -1) {
      state.doctors.splice(index, 1, { uid, ...state.doctors[index], ...doctor });
    } else {
      state.doctors.push({ uid, ...doctor });
    }
  },

  removeUser: (state, { uid }) => {
    const doctorIndex = state.doctors.findIndex((f) => f.uid === uid);
    if (doctorIndex !== -1) {
      state.doctors.splice(doctorIndex, 1);
      return;
    }

    const memberIndex = state.members.findIndex((f) => f.uid === uid);
    if (memberIndex !== -1) {
      state.members.splice(memberIndex, 1);
      return;
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setSelfUser: ({ commit }, { uid, selfUser }) => {
    commit("setSelfUser", { uid, selfUser });
  },
  setSelfUserEmail: ({ commit }, { email }) => {
    commit("setSelfUserEmail", { email });
  },
  setMember: ({ commit }, { uid, member }) => {
    commit("setMember", { uid, member });
  },
  setDoctor: ({ commit }, { uid, doctor }) => {
    commit("setDoctor", { uid, doctor });
  },
  removeUser: ({ commit }, { uid }) => {
    commit("removeUser", { uid });
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
