<template>
  <v-chip
    :color="labelConfig.color"
    :text-color="labelConfig.textColor"
    :x-small="true"
    class="px-2 tracking-widest translate-y-[-1px]"
  >
    {{ labelConfig.label }}
  </v-chip>
</template>

<script>
import { LABEL_TYPES } from "@/const/const";

export default {
  name: "UserTypeLabel",
  props: {
    // ラベル種別
    labelType: {
      type: String,
      required: true,
    },
    // ラベル種別その他
    labelTypeOther: {
      type: String,
      required: false,
    },
  },
  computed: {
    labelConfig() {
      if (this.labelType == "other") {
        const labelTypeOther = this.labelTypeOther || LABEL_TYPES.other.label;
        return {
          ...LABEL_TYPES.other,
          label: labelTypeOther,
        };
      }
      return LABEL_TYPES[this.labelType] || LABEL_TYPES.other;
    },
  },
};
</script>
