<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="h-full flex justify-center items-center">
      <div class="flex flex-col">
        <!-- ロゴ -->
        <v-img contain class="mb-4" height="140" :src="require(`../../assets/logo.svg`)"> </v-img>

        <!-- メールアドレス -->
        <validation-provider name="メールアドレス" rules="required|email">
          <v-text-field
            v-model="email"
            label="メールアドレス"
            type="email"
            prepend-inner-icon="mdi-email-outline"
            outlined
            dense
            @keyup.enter="login(invalid)"
          ></v-text-field>
        </validation-provider>

        <!-- パスワード -->
        <validation-provider name="パスワード" rules="required">
          <v-text-field
            v-model="password"
            label="パスワード"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            prepend-inner-icon="mdi-lock-outline"
            outlined
            dense
            @click:append="showPassword = !showPassword"
            @keyup.enter="login(invalid)"
          >
          </v-text-field>
        </validation-provider>

        <!-- ログインボタン -->
        <v-btn color="primary" elevation="0" block :disabled="invalid" @click="login(invalid)">
          ログイン
        </v-btn>

        <!-- パスワードを忘れた方はこちら -->
        <div class="my-4">
          <a @click="$router.push('/password-resetting').catch(() => {})">
            パスワードを忘れた方はこちら
          </a>
        </div>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { mapActions } from "vuex";
export default {
  name: "Login",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // メールアドレス
    email: "",

    // パスワード
    password: "",

    // パスワード表示制御
    showPassword: false,
  }),
  created() {
    // ログイン済みの場合はリダイレクト
    if (this.$store.state.user.selfUser) {
      const { type } = this.$store.state.user.selfUser;
      this.$router.push(type == "patient" ? "/input" : "/list").catch(() => {});
      return;
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // ログイン
    async login(invalid) {
      if (invalid) return;

      try {
        this.incrementRunningApiCount();

        // カスタムトークンを取得してログイン
        const func = this.$httpsCallable(this.$functions, "login");
        const token = await func({
          email: this.email,
          password: this.password,
          types: ["patient", "member"],
        });
        await signInWithCustomToken(getAuth(), token.data);

        // NOTE: ログイン後の画面遷移は App.vue の onAuthStateChanged で行っている
        // ユーザー種別ごとで遷移先が異なるため、画面遷移を行う前にユーザー情報を取得する必要があるため

        this.decrementRunningApiCount();
      } catch (error) {
        if (error.message === "auth/invalid-credential") {
          this.showError("メールアドレスまたはパスワードが違います");
        } else {
          this.showError(MESSAGES.ERRORS.UNEXPECTED);
        }
      }
    },
  },
};
</script>
